import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from '../Hook/useSnackbar'
function Footer(props) {
    const ggSheetAPI = process.env.REACT_APP_GG_SHEET_API
    const showSnackbar = useSnackbar()
    const { isChangeLanguage } = props
    const [emailSubscriber, setEmailSubscriber] = useState('')
    const isValidEmail = (input) => {
        // Biểu thức chính quy để kiểm tra địa chỉ email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(input)
    }
    const isDisabled = !isValidEmail(emailSubscriber)

    const handleSubmitSubscriber = async () => {
        try {
            const response = await axios.post(`${ggSheetAPI}/tabs/Subscriber`, {
                email: emailSubscriber,
            })
            if (response.status == 200) {
                setEmailSubscriber('')
                showSnackbar({
                    severity: 'success',
                    children: isChangeLanguage == 'ENG' ? 'Subscriber Successfully' : 'Đăng ký thành công',
                })
            }
        } catch (error) {}
    }
    return (
        <div className="font-manrope  md:px-0 sm:h-[554px] ">
            <div className="pb-4 sm:h-[473px]  bg-[#ECE9F5] ">
                <div className="2xl:mx-[80px] xl:mx-[70px] lg:mx-[60px] md:mx-[50px] sm:mx-[40px] mx-4">
                    <h2 className="pt-12 sm:pt-[75px] sm:text-[40px] font-extrabold ">Otto.</h2>
                    <p className=" sm:mt-[10px] text-[6px] sm:text-sm">
                        {isChangeLanguage == 'ENG'
                            ? `Your travel companion, Anywhere, Anytime`
                            : `Người bạn đồng hành trong mỗi chuyến đi`}
                    </p>
                    <p className="mt-2 sm:mt-4 md:mt-[85px] text-[10px] sm:text-2xl font-bold">
                        {isChangeLanguage == 'ENG' ? `Subscribe with Us` : `Subscribe with Us`}
                    </p>
                    <p className=" text-[6px] sm:text-sm">
                        {isChangeLanguage == 'ENG'
                            ? `Hear about our upcoming product releases`
                            : `Đăng kí để nhận những cập nhật mới nhất vê sản phẩm`}
                    </p>
                    <div className="mt-1 sm:mt-7 flex  gap-[5px]">
                        <input
                            value={emailSubscriber}
                            placeholder='Please enter your email here.'
                            onChange={(e) => setEmailSubscriber(e.target.value)}
                            className="my-2 md:m-0 w-[180px] h-6 lg:w-[425px] sm:h-[54px] outline-none border-[1px]
                             border-black bg-[#ECE9F5]  px-4 text-[8px] sm:text-xl rounded-[4px] sm:rounded-xl"
                        />
                        <button
                            disabled={isDisabled}
                            onClick={handleSubmitSubscriber}
                            className={`my-2 p-2 sm:p-4 md:m-0 sm:h-[54px] h-6  lg:w-[160px] flex items-center 
                            justify-center text-[6px] sm:text-base font-bold uppercase text-white ${isDisabled ? `bg-gray-400` : `bg-black`} 
                             rounded-[4px] sm:rounded-xl`}
                        >
                            {isChangeLanguage == 'ENG' ? `Subscribe` : `Đăng kí`}
                        </button>
                    </div>
                </div>
            </div>
            <div className="sm:h-[82px] bg-black md:flex md:items-center ">
                <div
                    className="2xl:mx-[80px] xl:mx-[70px] lg:mx-[60px] md:mx-[50px]
                 sm:mx-[40px] w-full py-2 sm:pt-4 px-4 md:p-0 flex text-white mx-auto"
                >
                    <Link to="/Privacy" className="text-[6px] sm:text-base font-manrope font-medium">
                        Privacy Notice
                    </Link>
                    <p className="text-[6px] sm:text-base font-manrope font-medium ml-auto">
                        Copyright, Otto(TM) 2023. All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
