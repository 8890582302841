import React from 'react'
import imageLgo from '../Img/logo.png'

const Loading = () => {
    return (
        <div className=" h-screen  flex justify-center items-center">
            <div className="absolute animate-spin rounded-full h-48 w-48 border-t-4 border-b-4 border-blue-400"></div>
            <p className="font-manrope-extrabold  text-2xl h-[52px] text-blue-700 leading-[52px]">Otto.</p>
        </div>
    )
}

export default Loading
