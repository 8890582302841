import './App.css'
import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Page/Home'
import Privacy from './Page/Privacy ';
import Navbar from './Component/Navbar';

function App() {
    const [isChangeLanguage, setIsChangeLanguage] = useState('ENG');

    const handleLanguageChange = (newLanguage) => {
        setIsChangeLanguage(newLanguage);
    }
    return (
        <Router>
            <Navbar isChangeLanguage={isChangeLanguage} callbackNavbar={handleLanguageChange} />
            <Routes>
               <Route path="/" element={<Home isChangeLanguage={isChangeLanguage}/>} />
               <Route path="/Privacy" element={<Privacy isChangeLanguage={isChangeLanguage} />} />
            </Routes>
        </Router>
    )
}

export default App
